<template>
    <div class="row px-0 mx-0 sticky-top" id="header">
      <div class="col-12">
        <div class="row" style="align-items: center;">
          <div class="col-2">
            <router-link to="/"><img src="../assets/asthma_logo.svg" id="asthmaLogo" /></router-link>
          </div>

          <div class="col-10 topNavRow" style="display: flex;">
            <div class="row mobileOnly">
              <div class="col-12" style="display: flex; justify-content: end;">
                <button class="yellowButton" id="topYellowButton2" data-bs-toggle="modal" data-bs-target="#signatureModal" @click="this.reflowCarousel()">A PROMISE TO YOURSELF</button>
              </div>
            </div>

            <div class="row" style="width: 100%;">
              <div class="col-12">
                <nav class="navbar navbar-expand-lg">
                  <button class="navbar-toggler collapsed" type="button" data-target="#mobileNav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="collapse" data-bs-target="#mobileNav">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>

                  <div class="collapse navbar-collapse" id="navbarSupportedContent"> 
                    <ul class="navbar-nav mr-auto">
                      <li class="nav-item active">
                        <router-link to="/#know-your-asthma-signature" class="nav-link knowyourasthmasignature-navbar">Know your asthma signature</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/#holistic-asthma-management" class="nav-link avoidingcorticosteroids-navbar">Holistic asthma management</router-link>
                      </li>
                      
                      <li class="nav-item">
                        <router-link to="/#oral-corticosteroids" class="nav-link knowyouroptions-navbar">Oral corticosteroids</router-link>
                      </li>

                      <li class="nav-item" id="lastNavItem">
                        <router-link to="/#promise-to-yourself" class="yellowButtonLink" data-event-category="Internal_link_click"  data-event-action="Click" data-event-label="Promise_yourself_btn"><button class="yellowButton signyourpledge-navbar" id="topYellowButton">A PROMISE TO YOURSELF</button></router-link>
                      </li>
                    </ul>
                    
                  </div>
                </nav>
              </div>
            </div>


            <div id="mobileNav" class="collapse">
              <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-link to="/#know-your-asthma-signature" class="nav-link knowyourasthmasignature-navbar"><h3 class="firstNavItem" data-bs-toggle="collapse" data-bs-target="#mobileNav">KNOW YOUR ASTHMA SIGNATURE</h3></router-link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <router-link to="/#holistic-asthma-management" class="nav-link avoidingcorticosteroids-navbar"><h3 data-bs-toggle="collapse" data-bs-target="#mobileNav">Holistic asthma management</h3></router-link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <router-link to="/#oral-corticosteroids" class="nav-link knowyouroptions-navbar"><h3 data-bs-toggle="collapse" data-bs-target="#mobileNav">Oral corticosteroids</h3></router-link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                      <router-link to="/#promise-to-yourself" data-event-category="Internal_link_click"  data-event-action="Click" data-event-label="Promise_yourself_btn"><button class="yellowButton signyourpledge-navbar" style="display: inline;">A PROMISE TO YOURSELF <img src="../assets/pen-icon.svg" /></button></router-link>
                    </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    
</template>

<script>
export default {
  name: 'HomeView',
  methods:
  {
    reflowCarousel()
    {
      this.$emit('reflowCarousel');
    }
  }
}
</script>
