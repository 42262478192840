import { createApp } from 'vue'
import { createStore } from "vuex";

import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Vue3Signature from "vue3-signature";
import '@/css/style.css';
import '@/css/responsive.css';
import router from './router'

const store = createStore({
    state : {
        carouselVisible: false,
        hasCustomSignature: false,
        customSignature: "",
        images : [
          { id: 1, url: require('./assets/signature-01.svg'), isCustom: false },
          { id: 2, url: require('./assets/signature-02.svg'), isCustom: false },
          { id: 3, url: require('./assets/signature-03.svg'), isCustom: false },
          { id: 4, url: require('./assets/signature-04.svg'), isCustom: false },
          { id: 5, url: require('./assets/signature-01.svg'), isCustom: false },
          { id: 6, url: require('./assets/signature-02.svg'), isCustom: false },
          { id: 7, url: require('./assets/signature-03.svg'), isCustom: false },
          { id: 8, url: require('./assets/signature-04.svg'), isCustom: false }
      ]
    },
    /*mutations: {
      changeCarouselVisibility (state) {
        state.carouselVisible = state;
      },
    },*/
    getters:
    {
      getCustomSignature: (state) =>
      {
          //console.log("Podcasts length: " + state.podcasts.length);
          if (state.hasCustomSignature)
          {
              return true;
          }
          return false;
      }
    }
  });

createApp({
  extends: App,
  beforeCreate()
  {
      //this.$store.commit('initialiseStorage');

      if (localStorage.getItem("asthma_signature") != null)
      {
        store.state.customSignature = localStorage.getItem("asthma_signature");
        store.state.images.unshift({ id: 0, url: store.state.customSignature, isCustom: true });
      }
  }
}).use(router).use(store).use(Vue3Signature).mount('#app')
