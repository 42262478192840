<template>
  <transition name="fade">
    <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            
            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="closeButton"><img src="../assets/icon-close.svg" alt="Close Icon" data-event-category="Internal_link_click"  data-event-action="/" data-event-label="Modal_Close" /></button>
          </div>
          <div class="modal-body">
            <!--<h1 class="smallerH1">SIGN YOUR PLEDGE</h1>
            <img src="../assets/top-blue-squiggle.png" class="blue-signature" style="margin: 0 auto; max-width: 30%; margin-bottom: 30px;" />-->
            <div id="signatureWrapper">
              <Vue3Signature ref="signature1" :sigOption="state.option" :clearOnResize="true" :w="'300px'" :h="'300px'" class="example sig1"></Vue3Signature>
              <Vue3Signature ref="signature2" :sigOption="state.option" :clearOnResize="true" :w="'400px'" :h="'400px'" class="example sig2"></Vue3Signature>
            </div>

            <!--<div class="row">
              <div class="col-12">
                    
                    <button class="greyBtn" @click="undo">UNDO</button>
              </div>
            </div>-->

            <div class="row">
              <div class="col-12">
                  <button class="greyBtn" @click="clear">CLEAR</button>
                  <button class="yellowButton submityourpledge-popup" style="display: inline;" v-if="!store.state.hasCustomSignature" @click="save('image/png')" data-event-category="Internal_link_click"  data-event-action="/" data-event-label="Modal_Submit_your_pledge">SUBMIT YOUR PLEDGE <img src="../assets/pen-icon.svg" /></button>
                  <button class="greenButton" style="display: inline;" v-if="store.state.hasCustomSignature" data-event-category="Internal_link_click"  data-event-action="/" data-event-label="Modal_Close">THANK YOU <img src="../assets/blue-tick.svg" /></button>
              </div>
            </div>

            
                    
                    <!--<button @click="save('image/png')">Save</button>-->
                    
                    <p style="text-align: center;" class="mt-4">By signing the pledge wall you are making a commitment to yourself to take ownership of your asthma&nbsp;management.</p>
                    
                    <h2 style="text-align: center;">Existing pledges to the wall</h2>

                    <img src="../assets/top-blue-squiggle.png" class="blue-signature" style="margin: 0 auto; max-width: 30%;" /><br />

                    <SignatureCarousel @updateSlide="updateSlide()" ref="signatureCarousel" id="signatureCarousel" :class="{ 'showCarousel' : $store.state.carouselVisible}"></SignatureCarousel> 
                    
                    
                    <!--<button @click="addWaterMark">addWaterMark</button>
                    <button @click="fromDataURL">fromDataURL</button>
                    <button @click="handleDisabled">disabled</button>-->
          </div>
          <div class="modal-footer">
            
            <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>-->
          </div>
        </div>
      </div>
    </transition>
</template>

<style scoped>
/*.modal-dialog
{
  max-width: 2000px !important;
}*/
</style>



<script setup>
import {reactive, ref} from 'vue'
import SignatureCarousel from "../components/SignatureCarousel.vue" 
import { onMounted } from 'vue';
import { useStore } from 'vuex'

onMounted(() => {
  
})

const store = useStore()


const updateSlide = () =>
{
  console.log("Update slide");
}


const state = reactive({
  count: 0,
  option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgba(255,255,255, 0)"
  },
  disabled: false,
  showCarousel: false
})

const signature1 = ref(null)
const signature2 = ref(null)

const save = (t) => {
  if (screen.width >= 430)
  {
    store.state.customSignature = signature2.value.save(t);
  }
  else
  {
    store.state.customSignature = signature1.value.save(t);
  }
  
  store.state.hasCustomSignature = true;
  
  if (store.state.images[0].id == 0)
  {
    store.state.images[0] = { id: 0, url: store.state.customSignature, isCustom: true };
  }
  else
  {
    store.state.images.unshift({ id: 0, url: store.state.customSignature, isCustom: true });
  }
  
  localStorage.setItem("asthma_signature", store.state.customSignature);

}

const clear = () => {
  if (screen.width >= 430)
  {
    signature2.value.clear();
  }
  else
  {
    signature1.value.clear();
  }
  
  store.state.hasCustomSignature = false;
}



/*const undo = () => {
  signature1.value.undo();
  
}*/

 
</script>

<style scoped>
.example{
  margin: 0 auto;
  border: 2px dashed;
}

#signatureWrapper
{
  /*height: 300px;
  width: 300px;*/
  margin: 0 auto;
}
.modal-dialog
{
  max-width: unset !important;
}
#signatureCarousel
{
  opacity: 0;
  transition: opacity 0.5s ease;
}

.myfade-enter-active, .myfade-leave-active {
  transition: opacity 1.5s
}
.myfade-enter, .myfade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}

.showCarousel
{
  opacity: 1.0 !important;
  transition: opacity 0.5s ease;
}
</style>
