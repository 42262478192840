<template>
  <TopNav @reflowCarousel="reflowCarousel()"></TopNav>
  <transition name="slide-fade">
    <router-view v-slot="{ Component }">
      <component ref="view" :is="Component" @externalLink="externalLink"/>
    </router-view>
  </transition>


  <div class="modal" tabindex="-1" id="exitModal">
    <div class="modal-dialog">
      <div class="modal-content">
        
        <div class="modal-header">
          <button type="button" class="close"  data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" class="exitCross">&times;</span>
          </button>
        </div>
        
        <div class="modal-body">
          <h4>You are leaving the myasthmasignature.co.uk website.</h4>

          <p class="exitText" style="margin-top: 30px;">By clicking on this link, you will be leaving this Sanofi hosted website, and depending on the link, will be redirected to either another Sanofi Group website (for example, to view the Sanofi Privacy Policy) or an independent third-party&nbsp;website.</p>

          <p class="exitText">Sanofi has no control over the content or availability of third-party sites or resources that are linked to and accepts no liability or responsibility for them or for any loss or damage that may arise from your use of them. The existence of a link between this website and any third-party site or resource in no way implies that Sanofi in any way approves of the content of these sites or resources, or any use to which such content maybe&nbsp;put.</p>

          <p class="exitText">Please ensure that you read the legal and privacy policy sections of any website you&nbsp;visit.</p>
          
          <button type="button" class="btn backBtn" data-bs-dismiss="modal">Go back to myasthmasignature.co.uk</button>
          <button type="button" class="btn continueBtn" @click="openExternalLink()">LEAVE THIS SITE</button>
          
        </div>
      </div>
    </div>
  </div>

  <FooterComponent @externalLink="externalLink"></FooterComponent>
</template>

<script>
import TopNav from "./components/TopNav.vue";
import FooterComponent from "./components/FooterComponent.vue"
import { Modal } from 'bootstrap'

export default {
  name: 'App',
  components: {
    TopNav,
    FooterComponent
  },
  mounted()
  {
    this.exitModal = new Modal("#exitModal", {});
  },
  methods:
  {
    openExternalLink()
    {
      window.open(this.exitURL, '_blank').focus();
      this.exitModal.hide();
    },
    reflowCarousel()
    {
      this.$refs.view.reflowCarousel();
    },
    externalLink(theURL)
    {
      this.exitURL = theURL;
      this.exitModal.show();
      return false;
    }
  },
  data() 
  {
      return {
        exitModal: null,
        exitURL: ""
      }
  }
}
</script>
