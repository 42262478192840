<template>
    <div class="row pb-2" id="footer">
      <div class="col-12">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-12 pt-3 mt-3">
              
              <p class="footnotes" v-if="this.$route.name == 'home'">
                <b>References:</b><br />
                <b>1.</b> Asthma + Lung UK. Types of asthma. Available at: <a @click="$emit('externalLink', 'https://www.asthmaandlung.org.uk/conditions/asthma/types-asthma')">https://www.asthmaandlung.org.uk/conditions/asthma/types-asthma</a>. Date last accessed: December 2023. 
                <b>2.</b> Oxford Academic Health Science Network. Consensus pathway for management of uncontrolled asthma in adults. Available at: <a @click="$emit('externalLink', 'https://www.oxfordahsn.org/our-work/respiratory/asthma-biologics-toolkit/aac-consensus-pathway-for-management-of-uncontrolled-asthma-in-adults/');">https://www.oxfordahsn.org/our-work/respiratory/asthma-biologics-toolkit/aac-consensus-pathway-for-management-of-uncontrolled-asthma-in-adults/</a>. Date last accessed: December 2023. 
                <b>3.</b> Centers for Disease Control and Prevention. Uncontrolled asthma among adults, 2016. Available at: <a @click="$emit('externalLink', 'https://www.cdc.gov/asthma/asthma_stats/uncontrolled-asthma-adults.htm');">https://www.cdc.gov/asthma/asthma_stats/uncontrolled-asthma-adults.htm</a>. Date last accessed: December 2023. 
                <b>4.</b> Global Initiative for Asthma (GINA). Pocket guide for asthma management and prevention 2022. Available at: <a @click="$emit('externalLink', 'https://ginasthma.org/wp-content/uploads/2022/07/GINA-2022-Pocket-Guide-WMS.pdf');">https://ginasthma.org/wp-content/uploads/2022/07/GINA-2022-Pocket-Guide-WMS.pdf</a>. Date last accessed: December 2023. 
                <b>5.</b> Kharaba Z, et al. <em>Front Public Health</em>. 2022;10:883784. 
                <b>6.</b> Chung LP, et al. <em>Respirology</em> 2020;25(2):161–172.
                <b>7.</b> Fanta CH, et al. An overview of asthma management. UpToDate. 2023. 
                <b>8.</b> Janezic A, et al. <em>J. Asthma</em>. 2020; 57: 654–662. 
                <b>9.</b> Mayo Clinic. Prednisone and other corticosteroids. Available at <a @click="$emit('externalLink', 'https://www.mayoclinic.org/steroids/art-20045692');">https://www.mayoclinic.org/steroids/art-20045692</a>. Date last accessed: December 2023. 
                <b>10.</b> Prednisolone SmPC. Available at: <a @click="$emit('externalLink', 'https://www.medicines.org.uk/emc/product/11713/smpc/');">https://www.medicines.org.uk/emc/product/11713/smpc/</a>. Date last accessed: December 2023.
                <b>11.</b> Maspero J, et al. <em>ERJ Open Res</em> 2022;8:00576–2021. 
                <b>12.</b> Asthma + Lung UK. Biologic therapies for severe asthma. Available at: <a @click="$emit('externalLink', 'https://www.asthmaandlung.org.uk/symptoms-tests-treatments/treatments/biologic-therapies');">https://www.asthmaandlung.org.uk/symptoms-tests-treatments/treatments/biologic-therapies</a>. Date last accessed: December 2023.
              
              
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-9">
              <img src="../assets/The_next_breath-logo.svg" style="display: block;" id="nextBreathLogo"/>

              <p>The Next Breath is a Sanofi &amp; Regeneron global initiative.<br />
                &copy;2023 Sanofi &amp; Regeneron – All rights reserved. Sanofi and Regeneron are registered trademarks.</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row pb-1" id="footer2">
      <div class="col-12">
        <div class="container py-3">
          <div class="row">
            <div class="col-12 col-md-9">
              <p><router-link to="/terms-of-use">Terms of Use</router-link> | <a style="text-decoration: underline; cursor: pointer;" @click="$emit('externalLink', 'https://www.sanofi.co.uk/en/privacy-policy')">Privacy Policy</a> | <router-link to="/cookie-policy">Cookie Policy</router-link> | <a style="text-decoration: underline; cursor: pointer;" @click="$emit('externalLink', 'https://www.sanofi.co.uk/en/contact')">Contact Us</a><br />
                Questions or Comments? Click <a style="color: white; text-decoration: underline; cursor: pointer;" @click="$emit('externalLink', 'https://www.sanofi.co.uk/en/contact');"><b>here</b></a> to contact Sanofi.<br />
                This website is intended for UK residents only and its contents have been developed and funded<br />by Sanofi and Regeneron.<br />
                MAT-XU-2301652 (v1.0) | December 2023</p>
            </div>
            <div class="col-12 col-md-3 align-self-end">
              <img src="../assets/sanofi-regeneron-logo.png" id="sanofiLogo" style="max-width: 50%;"/>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>