<template>
  

  <div class="container-fluid">

    

    <div class="row">
      <div class="col-12">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <img src="../assets/top-blue-squiggle.png" id="topSquiggle" alt="Image of blue line strike" />
            </div>
          </div>

          <div class="row" style="justify-content: center;">
            <div class="col-9">
              <h1 class="mainTitle">NO TWO ASTHMA SIGNATURES ARE THE SAME</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p class="introPara">If you have severe asthma, you’ll know it can prevent you from doing the things you want to do. If this feels like you, read on and find out how making a simple <router-link to="/#promise-to-yourself">promise&nbsp;to&nbsp;yourself</router-link> could result in improved asthma control.</p>
            </div>
          </div>

          <!--<div class="row">
            <div class="col-12">
              <button class="yellowButton signyourpledge-header" data-bs-toggle="modal" data-bs-target="#signatureModal" style="display: inline;" @click="this.reflowCarousel()">SIGN YOUR PLEDGE TODAY <img src="../assets/pen-icon.svg" /></button>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <div class="row wave py-0 my-0" id="wave-01">
      
    </div>

    
    <div class="row py-0 my-0" id="know-your-asthma-signature">
        <div class="col-12">
          <div class="container">
            <div class="row py-4 justify-content-between">
              <div class="col-12 col-lg-5 order-lg-first order-last">
                <img src="../assets/signatures.png" class="img-fluid" data-aos="fade-right" data-aos-delay="350" data-aos-duration="600" alt="Asthma signatures"/>
              </div>

              <div class="col-12 col-lg-6 py-4 px-4 order-first order-lg-first" data-aos="fade-left" data-aos-delay="600" data-aos-duration="600">
                <h2><span class="lightFont">Your signature,</span><br />your journey to control</h2>

                <img src="../assets/top-blue-squiggle.png" class="float-start d-block blue-signature" alt="Image of blue line strike" /><br />

                <p class="boldPara">Just like a person’s fingerprint is unique, each person has their own unique asthma signature – it’s the way they experience the condition, its severity and its triggers.<sup>1</sup></p>

                <p>Currently, there are around 200,000 people in the UK living with severe, uncontrolled asthma and if you are one of them, you will know its impact goes beyond the expected doctor appointments, worsening symptoms and decreased quality of life.<sup>2–4</sup> For example, people with uncontrolled severe asthma often report depression, discomfort, anxiety and sleeping problems and avoid going out and performing day-to-day tasks, all of which negatively affect quality of life.<sup>5</sup></p>

              </div>

            </div>
        </div>
      </div>
    </div>

    

    <div class="signing" aria-label="Female signing Asthma on glass">
      <div class="row wave py-0 my-0" id="wave-02"></div>
      <div class="row py-0 my-0" id="content-02">
      <div class="col-12">
        <div class="container">
          <div class="row centeriPad alterSpacing">
            <div class="col-12 col-lg-6" data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">

            </div>

            <div class="col-8 col-lg-6" data-aos="fade-left" data-aos-duration="600" data-aos-delay="350">
              <h2><span class="lightFont">Recognising</span><br />uncontrolled asthma</h2>
              <img src="../assets/top-blue-squiggle.png" class="float-start d-block blue-signature" style="width: 70%;" alt="Image of blue line strike" /><br />

              <p class="boldPara mt-3">If you have experienced 3 to 4 of these signs in the past 4 weeks, it’s time to talk to your doctor as your asthma is unlikely to be controlled, leaving you at greater risk of asthma attacks (also known as exacerbations) and hospitalisation.<sup>4,6</sup></p>

              <h4 class="signs">Signs:</h4>
              <div class="row">
                <div class="col-10">
                   

                  <div class="row" style="justify-content: space-between;">
                    <div class="col-5">
                      <div class="card">
                        <img src="../assets/icon-sun.svg" class="card-img-top" alt="Sun Icon"/>
                        <div class="card-body">
                          <p>Daytime symptoms more than <b>twice a&nbsp;week</b></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="card">
                        <img src="../assets/icon-symptoms.svg" class="card-img-top" alt="Symptoms Icon"/>
                        <div class="card-body">
                          <p><b>Any night-time waking</b> due to&nbsp;asthma</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="justify-content: space-between;">
                    <div class="col-5">
                      <div class="card">
                        <img src="../assets/icon-inhaler.svg" class="card-img-top" alt="Inhaler Icon"/>
                        <div class="card-body">
                          <p>Rescue inhaler use more than <b>twice a&nbsp;week</b></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="card">
                        <img src="../assets/icon-asthma.svg" class="card-img-top" alt="Asthma Icon"/>
                        <div class="card-body">
                          <p><b>Any activity limitation</b> due to&nbsp;asthma</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4 mt-4">
                    <div class="col-12">
                      <p style="text-align: right;" class="footnote">Adapted from Global Initiative for Asthma.<br />Pocket Guide for Asthma Management and Prevention 2022.<sup>4</sup></p>
                    </div>
                  </div>
                </div>
              </div>
              

            </div>

          </div>

        </div>
      </div>
      </div>
      <div class="row wave py-0 my-0" id="wave-03"></div>
    </div>
    

    

    <div class="row py-0 my-0" id="holistic-asthma-management">
      <div class="col-12">
        <div class="container">
          <div class="row mt-5 justify-content-between">
            <div class="col-12 col-lg-6" data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">
              <h2><span class="lightFont">HOLISTIC</span><br />ASTHMA MANAGEMENT</h2>

              <img src="../assets/top-blue-squiggle.png" class="float-start d-block blue-signature" alt="Image of blue line strike" /><br />
            </div>
          </div>

          <div class="row justify-content-between">
            <div class="col-12 col-lg-6" data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">
              <p class="boldPara pt-3">The main goals of asthma management are to relieve symptoms and reduce the risk of asthma exacerbations while minimising treatment side effects. A person with well-controlled asthma should be able to participate in work, school and sports without limitation due to breathing.<sup>7</sup></p>

              <p class="yellowBox yellowBoxDark mt-4 mb-4">When was the last time you had your asthma reviewed? It’s recommended you have your asthma reviewed at least&nbsp;annually.<sup>2</sup></p>

              <p>For treatments to be successful, it’s imperative that you take the treatments you have been prescribed as directed by your doctor. For example, a good inhaler technique is very important in ensuring your treatments are effectively delivered directly to the areas that need it. Nonetheless, mistakes with inhaler technique are more common than you would have thought.<sup>8</sup> So if you think you might have an issue, speak with your nurse or doctor as they can help confirm you are using them correctly or can help improve your technique.</p>

            </div>

            <div class="col-12 col-lg-5" data-aos="fade-left" data-aos-duration="600" data-aos-delay="600">
              <p>Alongside medications, there are several things you can do to minimise the impact of your severe asthma. These include:<sup>4</sup></p>

              <ul>
                <li><p>Minimising exposure to allergens like pollen and dust</p></li>

                <li><p>Stop smoking or limit your exposure to tobacco smoke</p></li>

                <li><p>Stop or reduce your use of vapes. Whilst this is a useful tool for stopping smoking, it can also increase the risk of symptoms</p></li>

                <li><p>Make sure you maintain a healthy weight</p></li>

                <li><p>Try to reduce or avoid stress</p></li>
              </ul>
              
              <p>There are several resources that provide good information on minimising the risk of severe asthma and we encourage you to take a look as they may help you avoid some treatments and their associated side effects:</p>

              <p class="links">
                <a @click="$emit('externalLink', 'https://www.asthmaandlung.org.uk/conditions/severe-asthma');" data-event-category="External_link_click"  data-event-action="/" data-event-label="https://www.asthmaandlung.org.uk/conditions/severe-asthma">Severe asthma overview</a>&nbsp;&nbsp;<span class="arrow">&gt;</span> <br />

                <a @click="$emit('externalLink', 'https://www.allergyuk.org/types-of-allergies/asthma-respiratory/');" data-event-category="External_link_click"  data-event-action="/" data-event-label="https://www.allergyuk.org/types-of-allergies/asthma-respiratory/">What is asthma?</a>&nbsp;&nbsp;<span class="arrow">&gt;</span> <br />

                <a @click="$emit('externalLink', 'https://www.asthmaandlung.org.uk/living-with/stop-smoking');" data-event-category="External_link_click"  data-event-action="/" data-event-label="https://www.asthmaandlung.org.uk/living-with/stop-smoking">Advice on stopping smoking</a>&nbsp;&nbsp;<span class="arrow">&gt;</span> <br />

                <a @click="$emit('externalLink', 'https://www.asthmaandlung.org.uk/conditions/asthma/asthma-triggers/stress');" data-event-category="External_link_click"  data-event-action="/" data-event-label="https://www.asthmaandlung.org.uk/conditions/asthma/asthma-triggers/stress">Advice on stress</a>&nbsp;&nbsp;<span class="arrow">&gt;</span> <br />
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="row wave py-0 my-0" id="wave-04">
      </div>

      <div class="row py-0 my-0" id="oral-corticosteroids">
        <div class="col-12">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-6">
                <h2 class="mt-5"><span class="lightFont">A COMMON SEVERE ASTHMA TREATMENT OPTION – </span><br />ORAL CORTICOSTEROIDS (OCS)</h2>
                <img src="../assets/top-blue-squiggle.png" class="float-start d-block blue-signature" alt="Image of blue line strike" /><br />
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                
                <p class="boldPara pt-3">OCS have been the mainstay of severe asthma treatment for over 60 years, and can effectively treat exacerbations, reduce relapses and hospital admissions, and save lives.<sup>6</sup></p>

                <p>You’re most likely to be prescribed OCS if your symptoms have flared up or if you have had an exacerbation. If either of these happen, you may be prescribed a short-term OCS (such as prednisone) for 5–10 days to regain control over your asthma.<sup>6</sup> These are important for your recovery and are unlikely to cause harmful side effects.<sup>9</sup> Once you’ve regained control and finished your course, your doctor will advise you how to safely stop taking them.</p>

                <p>However, if you keep getting these sorts of exacerbations, you may end up being prescribed long-term, daily OCS. This is known as maintenance dosing and can leave you susceptible to the negative effects of OCS that can lead to a reduced quality of life.<sup>6,9,10</sup></p>

                <p class="yellowBox">There are two ways to prescribe OCS – <b>short-term</b> (sometimes called short burst) which lasts a few days and <b>long-term</b> (sometimes called maintenance treatment) that can last weeks, months or years.<sup>6</sup></p>
                <p class="mb-5">Although short-term OCS are unlikely to cause side effects, there are known issues with long-term OCS use. It is estimated that around 90% of people with severe asthma have at least one condition linked to long-term OCS exposure.<sup>6,9,10</sup> In fact, guidelines recommend that they only be used as a last resort and should be reduced as soon as control has been regained.<sup>4</sup></p>
              </div>

              <div class="col-12 col-lg-6" id="signingContent" data-aos="fade-left" data-aos-duration="600" data-aos-delay="300">
                <p class="boldPara pt-3" style="text-align: center;">Examples of maintenance OCS-related side effects:<sup>4,6</sup></p>
                <section id="slider">
                    <input type="radio" name="slider" id="s1" checked class="custom-radio" @click="clearCarouselTimer()" @change="updateText(0)">
                    <input type="radio" name="slider" id="s2" class="custom-radio" @click="clearCarouselTimer()" @change="updateText(1)">
                    <input type="radio" name="slider" id="s3" class="custom-radio" @click="clearCarouselTimer()" @change="updateText(2)">
                    <input type="radio" name="slider" id="s4" class="custom-radio" @click="clearCarouselTimer()" @change="updateText(3)">
                    <input type="radio" name="slider" id="s5" class="custom-radio" @click="clearCarouselTimer()" @change="updateText(4)">
                    <input type="radio" name="slider" id="s6" class="custom-radio" @click="clearCarouselTimer()" @change="updateText(5)">
                    <input type="radio" name="slider" id="s7" class="custom-radio" @click="clearCarouselTimer()" @change="updateText(6)">
                    
                    <label for="s1" id="slide1"><img src="../assets/icon-01.svg" alt="Digestive issues icon"></label>
                    <label for="s2" id="slide2"><img src="../assets/icon-02.svg" alt="Obesity icon"></label>
                    <label for="s3" id="slide3"><img src="../assets/icon-03.svg" alt="Anxiety/depression icon"></label>
                    <label for="s4" id="slide4"><img src="../assets/icon-04.svg" alt="Osteoporosis icon"></label>
                    <label for="s5" id="slide5"><img src="../assets/icon-05.svg" alt="High blood pressure icon"></label>
                    <label for="s6" id="slide6"><img src="../assets/icon-06.svg" alt="High cholesterol icon"></label>
                    <label for="s7" id="slide7"><img src="../assets/icon-07.svg" alt="Diabetes icon"></label>

                    
                </section>
                <p class="slideTitle" id="slideText" ref="slideText">Digestive issues</p>
                <div class="slideInnerContent">
                  <p class="boldPara">A real impact on your quality of life</p>
                  <p>The adverse events associated with long-term OCS use can have a major impact on your quality of life.<sup>6,11</sup></p>

                  <p>In addition to the physical effects of long-term OCS, such as weight gain and digestive issues, depression and anxiety can add to the already high toll of asthma, making daily life difficult to navigate.<sup>6,11</sup></p>

                  <p class="boldParaSmaller">Get the most out of your next consultation by downloading our Discussion Guide</p>

                  <a href="./Asthma Signature discussion guide_v1.pdf" download="Asthma Signature discussion guide_v1.pdf" style="text-decoration: none;" data-event-category="PDF_download"  data-event-action="/" data-event-label="Discussion_guide"><button class="yellowButton mt-4 dgdownload">DISCUSSION GUIDE <img src="../assets/pen-icon.svg" alt="Pen Icon" /></button></a>

                </div>
                

                
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row wave py-0 my-0" id="wave-05">
      </div>
    </div>

    <div class="row py-0 my-0" id="promise-to-yourself">
      <div class="col-12">
        <div class="container">
          <div class="row pt-5">
            <div class="col-12 col-lg-6" data-aos="fade-right" data-aos-duration="600" data-aos-delay="300">
              <h2>A PROMISE TO YOURSELF<br /><span class="lightFont">AND YOUR ASTHMA</span></h2>

              <img src="../assets/top-blue-squiggle.png" class="float-start d-block blue-signature" alt="Image of blue line strike" /><br />
              

              <transition name="fade">
                <div id="moreText" class="mt-3" v-if="true">
                  <p>The first steps on the way to improved asthma management can be daunting, so it’s a good idea to make a promise to yourself to do just that. It might be as simple as talking to your doctor, but it could be to make an appointment to get your asthma assessed. Whatever you decide to do, we recommend that you make this promise real and tangible by signing our pledge wall and commit yourself to seeking improved asthma management and an increased quality of life.<sup>2,12</sup></p>

                  <p>This is more than just a commitment to yourself, it’s a commitment to your family and friends and your futures&nbsp;together.</p>


                  <!--<p class="largePara mt-3"><b>Seek better treatment and an improved quality of life through targeted therapy.<sup>2,11</sup></b></p>-->

                  <p class="largePara"><b>Click below and pledge to take ownership of your asthma&nbsp;management.</b></p>

                  <button class="yellowButton mb-5 signyourpledge-treatmentoptions" data-bs-toggle="modal" data-bs-target="#signatureModal" @click="this.reflowCarousel()" data-event-category="Internal_link_click"  data-event-action="Click" data-event-label="Promise_yourself_btn">MAKE YOUR PROMISE TODAY <img src="../assets/pen-icon.svg" alt="Pen Icon" /></button>

                  <h2><span class="lightFont">TAKING YOUR FIRST STEPS TO</span><br />IMPROVED ASTHMA MANAGEMENT</h2>

                  <img src="../assets/top-blue-squiggle.png" class="float-start d-block blue-signature" alt="Image of blue line strike" /><br />

                  <p class="mt-3">We know talking to doctors isn’t always easy, that’s why we have developed this Discussion Guide. The goal should be to identify a way of life and asthma management that better fits your asthma signature. This Discussion Guide will help you in your next consultation to talk to your doctor about any concerns you may have about your asthma, your lifestyle and the treatments you have been prescribed or may be prescribed in the future.</p>
                  <p class="largePara"><b>Get the most out of your next consultation by downloading our Discussion&nbsp;Guide</b></p>

                  <a href="./Asthma Signature discussion guide_v1.pdf" download="Asthma Signature discussion guide_v1.pdf" style="text-decoration: none;" data-event-category="PDF_download"  data-event-action="/" data-event-label="Discussion_guide"><button class="yellowButton mt-4 dgdownload">DISCUSSION GUIDE <img src="../assets/pen-icon.svg" alt="Pen Icon" /></button></a>
                </div>
              </transition>

            </div>

            <div class="col-12 col-lg-6" data-aos="fade-left" data-aos-duration="600" data-aos-delay="600">
              <img src="../assets/blue-signature.svg" class="blueSignatureImage" alt="Signature" />
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row wave py-0 my-0" id="wave-06">
    </div>

    <div class="row py-0 my-0" id="content-06" data-aos="fade-down" data-aos-duration="600" data-aos-delay="700">
      <div class="col-12">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 style="text-align: center;">Existing pledges to the wall</h2>
              <img src="../assets/top-blue-squiggle.png" class="blue-signature" style="max-width: 30%;" alt="Image of blue line strike" />
            </div>
          </div>
        </div>
          <div class="row py-5">
            <div class="col-12">
              <SignatureCarousel @updateSlide="updateSlide()"></SignatureCarousel>
            </div>
          </div>
        
      </div>
    </div>

    


      <!-- Modal -->
      <div class="modal fade" id="signatureModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <SignatureModal ref="signatureModal"></SignatureModal>
      </div>
  </div>

</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import SignatureModal from "../components/SignatureModal.vue"
import SignatureCarousel from "../components/SignatureCarousel.vue"
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  name: 'HomeView',
  components: {
    SignatureModal,
    SignatureCarousel,
  },
  mounted()
  {
    console.log("Custom Signature: " + this.$store.state.hasCustomSignature);
    AOS.init({once: true});

    this.theTimer = setInterval(() => {
      this.carouselIndex++;
      if (this.carouselIndex == 8)
      {
        this.carouselIndex = 1;
      }
      try
      {
        document.getElementById('s' + this.carouselIndex).checked = true;
        this.updateText(this.carouselIndex - 1);
      }
      catch (err)
      {
        console.log("");
      }
      
    }, 2500);

    var slider = document.getElementById('slider');

    slider.addEventListener("touchstart", this.startTouch, false);
    slider.addEventListener("touchmove", this.moveTouch, false);

  },
  methods:
  {
    clearCarouselTimer()
    {
      clearInterval(this.theTimer);
    },
    updateText(index)
    {
      var titles = ["Digestive issues", "Obesity", "Anxiety/depression", "Osteoporosis<br />(weak bones)", "High blood pressure", "High cholesterol", "Diabetes", "Osteoporosis (weak bones)"];
      this.$refs.slideText.innerHTML = titles[index];
    },
    updateSlide()
    {

    },
    swipeRight()
    {
      this.clearCarouselTimer();
      this.carouselIndex--;
      if (this.carouselIndex == 0)
      {
        this.carouselIndex = 7;
      }
      document.getElementById('s' + this.carouselIndex).checked = true;
      this.updateText(this.carouselIndex - 1);
    },
    swipeLeft()
    {
      this.clearCarouselTimer();
      this.carouselIndex++;
      if (this.carouselIndex == 8)
      {
        this.carouselIndex = 1;
      }
      document.getElementById('s' + this.carouselIndex).checked = true;
      this.updateText(this.carouselIndex - 1);
    },  
    reflowCarousel()
    {
      setTimeout(() => {
        this.$refs.signatureModal.$refs.signatureCarousel.reflow();
        console.log("State is: " + this.$store.state.carouselVisible);
        this.$store.state.carouselVisible = true;
        //this.$refs.signatureModal.showCarousel = !this.$refs.signatureModal.showCarousel;
        //this.$refs.signatureModal.$refs.isHidden = true;
      }, 500);
    },  
    toggleReadMore()
    {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded)
      {
        this.lessOrMore = "less";
      }
      else
      {
        this.lessOrMore = "more";
      }
    },
    startTouch(e) {
      this.initialX = e.touches[0].clientX;
      this.initialY = e.touches[0].clientY;
      //console.log("Touch start");
    },
    moveTouch(e) {
      if (this.initialX === null) {
        return;
      }
    
      if (this.initialY === null) {
        return;
      }
    
      var currentX = e.touches[0].clientX;
      var currentY = e.touches[0].clientY;
    
      var diffX = this.initialX - currentX;
      var diffY = this.initialY - currentY;
    
      if (Math.abs(diffX) > Math.abs(diffY)) {
        // sliding horizontally
        if (diffX > 0) {
          // swiped left
          this.swipeLeft();
        } else {
          // swiped right
          this.swipeRight();
        }  
      } else {
        // sliding vertically
        if (diffY > 0) {
          // swiped up
          console.log("swiped up");
        } else {
          // swiped down
          console.log("swiped down");
        }  
      }
    
      this.initialX = null;
      this.initialY = null;
      
      e.preventDefault();
    }

  },
  data() 
  {
    return {
      isExpanded: false,
      lessOrMore: "more",
      theTimer: 0,
      carouselIndex: 1,
      initialX: null,
      initialY: null
    }
  }
}
</script>
