<template>
    <img :src="slideURL" class="signatureImage" ref="slideId" v-if="!isCustom" />

    <div class="signatureBG" v-if="isCustom">
        <img :src="slideURL" class="signatureImage" style="opacity: 0; position: absolute;" />
        <img :src="slideURL" class="signatureImage" style="opacity: 1; width: 80%; height: 80%; left: 10%; top: 10%; position: absolute;" />
    </div>
</template>


<script>
export default {
  name: 'CarouselSlide',
  props: {
    slideURL: String,
    slideId: Number,
    isCustom: Boolean
  }
}
</script>

<style scoped>
.signatureBG
{
    background-image: url("../assets/signature-bg.svg");
    width: 100%;
    height: 100%;
}

</style>