import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CookiePolicy.vue')
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import(/* webpackChunkName: "about" */ '../views/404View.vue')
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  },
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior(to, from, SavedPosition) 
  {
    if (to.hash) 
    {
      /*if(window.location.toString().includes("terms"))
      {
        const el = window.location.href.split("#")[2];

        const y = document.getElementById(el).getBoundingClientRect().top - 100;
        window.scrollTo({top: y, behavior: 'smooth'});

      }
      else
      {*/
        const el = window.location.href.split("#")[2];
        if (el.length) 
        {
          document.getElementById(el).scrollIntoView({ behavior: "smooth" });
        }
      //}
      
    } 
    else if (SavedPosition) 
    {
      return SavedPosition;
    } 
    else 
    {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router
