<template>
    <Carousel @update:modelValue="updateSlide()" :wrapAround="true" :autoplay="3200" :mouseDrag="true" :touchDrag="true" :itemsToShow="4" ref="theCarousel" :breakpoints="breakpoints">

    
        <Slide v-for="slide in this.$store.state.images" :key="slide.id" >
            <SignatureImage :slideId="slide.id" :slideURL="slide.url" :isCustom="slide.isCustom"></SignatureImage>
        </Slide>
        <template #addons>
            <Pagination />
        </template>

    </Carousel>
</template>



<script>

import { Carousel, Slide, Pagination } from 'vue3-carousel';
import SignatureImage from "../components/SignatureImage.vue";

export default {
    name: 'SignatureCarousel',
    components:
    {
        Carousel,
        Slide, 
        Pagination,
        SignatureImage
    },
    mounted()
    {
        console.log("Signature carousel: " + this.$store.state.hasCustomSignature);
        this.hasCustomSignature = this.$store.state.hasCustomSignature;
    },
    methods:
    {
        updateSlide()
        {
            console.log("Update slide!");
        },
        reflow()
        {
            this.$refs.theCarousel.restartCarousel();
            
            //console.log("Reflow...");
        },
        addImage()
        {
            
        }
    },
    data() 
    {
        return {
            hasCustomSignature: false,
            breakpoints: {
                375: {
                    itemsToShow: 1.5,
                    snapAlign: 'center',
                },
                430: {
                    itemsToShow: 1.75,
                    snapAlign: 'center',
                },
                768: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                1024: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                1440: {
                    itemsToShow: 4,
                    snapAlign: 'center',
                },
                1920: {
                    itemsToShow: 6,
                    snapAlign: 'center',
                },
                3000: {
                    itemsToShow: 6,
                    snapAlign: 'center',
                }
            }
        }
    }
}
</script>